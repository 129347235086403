import './styles.css';
import App from './app';
import { $ } from './helpers';

function ready(fn) {
  var d = document;
  d.readyState === 'loading' ? d.addEventListener('DOMContentLoaded', fn) : fn();
}

function isPlaying() {
  const player = $('audio');
  return !player.paused && !player.ended && 0 < player.currentTime;
}

ready(() => {
  const { innerWidth } = window
  const app = new App();
  const body = $('body');

  if (innerWidth > 767) {
    const gifImage = app.createImage();
    $('.image-wrap').appendChild(gifImage);
  }

  const player = app.createPlayer();

  setTimeout(() => {
    if (!isPlaying()) {
      player.play()
    }
  }, 5000);

  $('.player-nav').appendChild(player);
});
