import images from '../static/gifs/*.gif';

class App {
  constructor() {
    this.windowWidth = window.innerWidth;
    this.imagePath = 'static/gifs';
    this.serverAddress = 'https://listen.frilo.cool/radio/8000/live.mp3';
  }

  createPlayer() {

    const player = document.createElement('audio');
    const source = document.createElement('source');

    player.setAttribute('autoplay', true);
    player.setAttribute('controls', true);

    source.setAttribute('src', this.serverAddress);
    source.setAttribute('type', 'audio/mpeg');

    player.appendChild(source);

    return player;
  }

  createImage() {
    const img = document.createElement('img');
    const path = this.getImagePaths();

    img.setAttribute('src', path);
    img.setAttribute('alt', 'frilo radio');
    img.setAttribute('class', 'graphic-internet-friend');

    return img;
  }

  getImagePaths() {
    const imageArray = Object.keys(images);
    const path = Object.values(images)[Math.floor(Math.random() * imageArray.length)];
    return path;
  }
}

export default App;
